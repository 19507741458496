export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const GET_ARTICLE_FEEDS_REQUEST = "GET_ARTICLE_FEEDS_REQUEST";
export const GET_ARTICLE_FEEDS_SUCCESS = "GET_ARTICLE_FEEDS_SUCCESS";
export const GET_ARTICLE_FEEDS_FAILURE = "GET_ARTICLE_FEEDS_FAILURE";

export const GET_TEAMS_REQUEST = "GET_TEAMS_REQUEST";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAILURE = "GET_TEAMS_FAILURE";

export const GET_TEAM_NUM_REQUEST = "GET_TEAM_NUM_REQUEST";
export const GET_TEAM_NUM_SUCCESS = "GET_TEAM_NUM_SUCCESS";
export const GET_TEAM_NUM_FAILURE = "GET_TEAM_NUM_FAILURE";

export const GET_SELF_TEAM_REQUEST = "GET_SELF_TEAM_REQUEST";
export const GET_SELF_TEAM_SUCCESS = "GET_SELF_TEAM_SUCCESS";
export const GET_SELF_TEAM_FAILURE = "GET_SELF_TEAM_FAILURE";

export const GET_CONTEST_ID_REQUEST = "GET_CONTEST_ID_REQUEST";
export const GET_CONTEST_ID_SUCCESS = "GET_CONTEST_ID_SUCCESS";
export const GET_CONTEST_ID_FAILURE = "GET_CONTEST_ID_FAILURE";

export const UPDATE_USER = "UPDATE_USER";

export const VERIFY_TOKEN_REQUEST = "VERIFY_TOKEN_REQUEST";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_FAILURE = "VERIFY_TOKEN_FAILURE";
