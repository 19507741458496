import {
  BackTop,
  Button,
  Layout,
  ConfigProvider,
  Menu,
  Typography,
} from "antd";
import zhCN from "antd/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import QueueAnim from "rc-queue-anim";
import React, { useState } from "react";
import { Provider } from "react-redux";
import {
  //BrowserRouter as Router,
  HashRouter as Router,
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
} from "react-router-dom";
import logo from "./assets/logo.png";
import corpName from "./assets/name.png"

import store from "./redux/store";


import HomeSite from "./sites/HomeSite";
import NotFoundSite from "./sites/NotFoundSite";



import styles from "./App.module.css";
import { MenuProps } from "antd/lib/menu";
import EmblemPic from "./assets/1007827718.jpg"

import AuthRoute from "./components/AuthRoute";

import { Console } from "console";

const { Header, Footer } = Layout;
const { Title } = Typography;

moment.locale("zh-cn");

export type Site = "home" | "weekly" | "edc" | "ts" | "others";

const App = () => {
  const getRoute = ({ location }: RouteProps) => {
    const pathname = "/" + location!.pathname.split("/")[1];
    // alert(pathname);
    const matchedRoute = routes.find((item) => pathname === item.to);
    const Component = matchedRoute ? matchedRoute.component : NotFoundSite;
    // const Component = NotFoundSite;

    const authRequired = matchedRoute && matchedRoute.auth;

    const homeRoute = () => <Redirect to="/home" />;
    const siteRoute = (props: RouteComponentProps<any>) => (
      <Component {...props} setSite={setSite} />
    );

    return (
      <div style={{ position: "relative" }}>

        <Switch location={location}>
          <Route exact={true} path="/" render={homeRoute} />
          <QueueAnim type={["top", "bottom"]}>
          {/* <QueueAnim type={["right", "left"]}> */}
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}
              key={pathname}
            >

              <Route location={location} path="/:url" render={siteRoute} />
              <Footer className={styles.footer}>
                © 2020 BrainPercep®
                  <a target="_blank" href="https://beian.miit.gov.cn/">
                    <p style={{color:"#939393"}}>津ICP备20005972号  <img src={EmblemPic}/> 津公网安备 12011602000439号</p>
                  </a>

              </Footer>

            </div>
          </QueueAnim>
        </Switch>
      </div>
    );
  };

  const [site, setSite] = useState<Site>("home");

  const onHeaderMenuSelect: MenuProps["onSelect"] = (item) =>
    setSite(item.key as Site);

  return (
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <Router>
          <Layout>
            <Header className={styles.header}>
              <div className={styles.logoContainer}>
                <div>
                  {/* logo */}
                  <img className={styles.logo} src={corpName} alt="logo" />
                </div>

                {/* <Title style={{ margin: "auto", marginLeft: 10 }} level={3}>
                BrainPercep® 维智脑数据服务(天津)有限公司<br></br>

                </Title> */}



              </div>
              <Menu
                className={styles.menu}
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={["home"]}
                selectedKeys={[site]}
                onSelect={onHeaderMenuSelect}
              >
                <Menu.Item key="home">
                  <Link to="/home">首页</Link>
                </Menu.Item>
                <Menu.Item key="pmar">
                  {/* <Link to="/weekly">pmar</Link> */}
                  <a href="https://brainpercep.com/pmar">pmar</a>
                </Menu.Item>
                {/* <Menu.Item key="ts">
                  <Link to="/teamstyle">site2</Link>
                </Menu.Item>
                <Menu.Item key="info">
                  <a href="https://info.eesast.com">Info</a>
                </Menu.Item> */}
              </Menu>
              {/* <div className={styles.toolbar}>
                <Link to="/profile">
                  <Button icon="user" />
                </Link>
              </div> */}
            </Header>
            <Route render={getRoute} />
          </Layout>
          <BackTop />
        </Router>
      </ConfigProvider>
    </Provider>
  );
};

const routes = [
  { to: "/home", component: HomeSite, auth: false },
  // { to: "/profile", component: ProfilePage, auth: false },
];

export default App;
