import { Carousel } from "antd";
import React from "react";
import styles from "./NewsPage.module.css";
import { HomeSitePage } from "../sites/HomeSite";
import sys_img from "../assets/sys_pic.png"
import pic2 from "../assets/4-cut.png"
const news = [
  {
    title: "多模态神经影像数据分析系统",
    content: "多模态神经影像数据的数据管理、特征挖掘、特征表达等。",
    image: sys_img,
    // image:"http://59.110.165.152/static/media/sys_pic.png"
  },
  {
    title: "多模态神经影像数据的特征挖掘与表达",
    content: "神经影像数据分析辅助医疗诊断",
    image: pic2,
    // image:"http://59.110.165.152/static/media/4-cut.png"
  },
  // {
  //   title: "新生信息知识竞赛",
  //   content:
  //     "新生们登上信息学院的大舞台，凭借自己的知识与才智，展开一场激烈的角逐。",
  //   image:
  //     "https://api.eesast.com/static/images/2019informatics-knowledge-competition.jpg",
  // },
  // {
  //   title: "硬件设计大赛",
  //   content: "课赛结合，快速入门，感受硬件的魅力",
  //   image:
  //     "https://api.eesast.com/static/images/2019hardware-design-competition.jpg",
  // },
];

export interface INewsPageProps {
  setPage: (page: HomeSitePage) => void;
}

const NewsPage: React.FC<INewsPageProps> = ({ setPage }) => {
  setPage("news");

  return (
    <Carousel autoplay effect="fade">
      {news.map((news) => (
        <div key={news.title} className={styles.container}>
          <div
            className={styles.background}
            style={{
              backgroundImage: `url(${news.image})`,
            }}
          />
          <div className={styles.center}>
            <img className={styles.image} src={news.image} alt={news.title} />
          </div>
          <div className={styles.description}>
            <h2>{news.title}</h2>
            <p>{news.content}</p>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default NewsPage;
