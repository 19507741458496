import { Tabs, Typography, Divider } from "antd";
import React from "react";
import styles from "./ContestPage.module.css";
import { HomeSitePage } from "../sites/HomeSite";
import sys_img from "../assets/sys_pic.png"
// import pic2 from "../assets/图4-cut.png"
import humanPic1 from "../assets/humanPic1.png"
const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

export interface IContestPageProps {
  setPage: (page: HomeSitePage) => void;
}

const ContestPage: React.FC<IContestPageProps> = ({ setPage }) => {
  setPage("contests");

  return (
    <Tabs
      tabBarStyle={{
        marginTop: 48,
      }}
      tabPosition="left"
    >
      <TabPane tab="多模态神经影像数据分析系统" key="mmdps">
        <Typography className={styles.content}>
          <Title level={2}>多模态神经影像数据分析系统</Title>
          <Title level={3}>(一)	项目简介</Title>
          <Paragraph className={styles.description}>
          多模态神经影像数据（包括 CT、MRI、 EEG、MEG 等）已经广泛应用于医学、神经科学、心理学等学科的科学研究和临床应用，怎样充分利用这些数据为科研和临床服务，是目前学术界和应用领域共同面临的问题。虽然目前有很多针对神经影像数据处理的开源软件和和3D可视化工具包，但还没有一个完整的适用于跟踪研究的集成系统。本项目研究开发的“多模态神经影像数据分析系统”（图1），用全自动智能分析方法，提供数据管理、联合临床诊断、治疗和实验方案等多模态信息的脑组织结构和脑功能的特征挖掘、直观形象的特征表达等数据分析服务。它可以充分挖掘神经影像数据信息、满足跟踪对比研究的需要，解决神经科学和精准医学共同面临的科学决策问题。
          <img
              className={styles.img}
              alt="mmdps"
              // src={"http://59.110.165.152/static/media/sys_pic.png"}
              // src={window.location.origin+"./assets/humanPic1.png"}
              src = {sys_img}
            />
          </Paragraph>
          <Paragraph className={styles.description}>
          维智脑数据服务（天津）有限公司在上述数据分析系统（算法、软件和数据库）雏形的基础上，组建技术团队在原技术的基础上开发了一套可以提供商业化服务的数据分析系统（多模态神经影像数据分析系统）；组建市场开拓团体在医院、康复医疗中心以及康复机器人等相关市场开拓业务，在下述具体的领域提供相关数据分析服务：
          <ul>
            <li>
            	针对中枢神经系统受损患者康复治疗方案的效果评估和预测；
            </li>
            <li>
              针对脑肿瘤、脑血管等疾病的筛查和预警；
            </li>
            <li>
              康复机器人临床应用。
            </li>
          </ul>
          </Paragraph>
          <Divider />
          <Title level={3}>(二)	应用说明</Title>
            <Paragraph className={styles.description}>
            本系统已经应用在北京天坛医院、北京清华长庚医院、北京航天中心医院等多家医院的多模态脑磁共振信号和多通道EEG的数据分析中。目前应用包括分析神经胶质瘤高低级别，定量脑肿瘤体积，对脑肿瘤的不同组织成分和脑卒中受损组织进行定量标注，分析脑组织受损对脑神经功能连接和结构连接的影响。在神经康复领域，对脊髓病人的灰质结构和功能网络联合分析，结合临床评分，为找到可以用于病情评价的特征供了实例。该项目在长庚医院进行了持续近五年的百余人的临床验证，对中枢调控和外周强化治疗方案进行评估，获得了经颅直流电刺激和脑机接口训练的脑网络拓扑特征模型，以及同量表评分的正／负相关特征模型。由清华大学和北京清华长庚医院联合完成的《多模态磁共振在神经康复评估中的应用研究》项目，获得了2019年度中国康复医学会科学技术奖二等奖（中康发［2019］53号）。
            </Paragraph>
          <Title level={3}>(三)	效益分析</Title>
            <Paragraph className={styles.description}>
            从临床应用需求看，临床神经影像数据分析方面的需求非常大。据统计，每年我国脑神经疾病发病数为肿瘤 200 万、卒中 750 万、外伤 1060 万。这些疾病的诊断和治疗都依赖神经影像数据分析。这些疾病都可能导致中枢神经功能障碍，后期的神经康复也需要神经影像数据分析的指导。鉴于技术新颖、数据处理快速、分析应用便捷等特点，该系统已经成为长庚医院神经康复研究和临床应用的重要工具，并辐射到多家医院，具有良好的推广潜力。
            </Paragraph>
            <Paragraph className={styles.description}>
            从科学研究需求看，国家脑计划即将实施，精准医疗和智慧医疗等国家重大科研专项正在推进。通过多模态神经影像数据分析，可以找到各种中枢神经系统疾病的生物标志物（biomarker），研究神经康复过程中的神经可塑性机理，以更好的方式辅助临床应用。脑卒中、脊髓损伤等神经损伤疾病是我国乃至全世界面临的重要健康问题，研发高效、便捷、神经功能评价工具，完成无创新神经康复技术以及康复机器人并进行临床功能验证，尽早投入使用，为神经损伤患者提供更优质、安全、有效、便捷的治疗方案及临床路径，将有助于提高神经损伤患者功能康复效率，减少残疾发生，降低医疗花费，避免医疗资源浪费，是医疗科技创新的重要举措。
            </Paragraph>
            <Divider />
          <Title level={3}>(四)	项目团队</Title>

            <Paragraph className={styles.description}>
              <ul >
                <li style={{fontWeight:"bold"}}>
                  首席科学家：
                </li>
              </ul>
              <img
              className={styles.human_img}
              alt="mmdps"
              src={humanPic1}
              // src="http://59.110.165.152/static/media/humanPic1.png"
              />
            <p>
            窦维蓓，清华大学教授，全国信息技术标准化技术委员会多媒体分委会第四届专家委员，ISO/IEC JTC 1/SC29 WG11工作组专家，中国数字音视频编解码技术标准工作组（AVS）总体组成员、音频组组长。北京信息科学与技术国家研究中心“感知网芯片与系统研究”团队骨干。窦维蓓长期从事物联网多传感器数据融合技术的研发，提出了模糊信息融合架构和模糊Kappa一致性评估方法，在人脑神经系统信息挖掘、音视频联合识别与编码传输等领域有广泛应用；发表高水平学术论文百余篇，获授权发明专利19项、软件著作权1项。负责或参加多项国家自然基金项目和科技部项目，负责完成企业合作和国际合作项目十余项；负责完成了高效感知编码国家标准GB/T 33475.3-2018的制定，该标准目前已广泛应用于全景声制造、音像存储等领域；担任IEEE SA P1857.8 子课题主席和首席编辑，完成IEEE标准IEEE Std. 1857.2TM-2013的编辑。
            </p>
            </Paragraph>
            <Paragraph className={styles.description}>
            <ul >
                <li style={{fontWeight:"bold"}}>
                  技术骨干：
                </li>
              </ul>
            <p>
              葛云祥（CTO）
            </p>
            <p>
              郝泽轩（清华大学电子工程系在读博士生）
            </p>
            </Paragraph>

            <Paragraph className={styles.description}>
            <ul >
                <li style={{fontWeight:"bold"}}>
                  医学顾问：
                </li>
              </ul>
            <p>
             潘钰（康复医学博士、长庚医院康复科主任、主任医师）
            </p>
            <p>
              王劲（长庚医院副院长、神经外科主任医师）
            </p>
            <p>
              李少武（医学博士、北京天坛医院放射科主任医师、北京神经外科研究所功能神经成像中心负责人）
            </p>
            </Paragraph>
            <Divider />
          <Title level={3}>(五)	合作方式</Title>
            <Paragraph className={styles.description}>
            欢迎优秀的人才、资本与我们联系。任何形式的合作，包括合作研发、数据分析服务等，请联系公司首席科学家窦维蓓教授。
            </Paragraph>

            <Paragraph style={{fontWeight:"bold"}} className={styles.description}>
              <p>
                联系电话：13910663993
              </p>
              <p>
                Email: douwb@tsinghua.edu.cn
              </p>

            </Paragraph>


          {/* <Title level={4}>精彩回顾</Title>
          <Paragraph disabled style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="ts16"
              src="https://api.eesast.com/static/images/ts16.jpg"
            />
            <br />
            队式十六——深蓝 (Deep Blue)
            <br />
          </Paragraph>
          <Paragraph disabled style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="ts17"
              src="https://api.eesast.com/static/images/ts17.jpg"
            />
            <br />
            队式十七——Stellar Craft
            <br />
          </Paragraph>
          <Paragraph disabled style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="ts18"
              src="https://api.eesast.com/static/images/ts18.jpg"
            />
            <br />
            队式十八——CPClash
            <br />
          </Paragraph>
          <Paragraph disabled style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="ts19"
              src="https://api.eesast.com/static/images/ts19.jpg"
            />
            <br />
            2018 年，队式十九作为 A1 组比赛合并到 THU-AI 中
            <br />
          </Paragraph>
          <Paragraph disabled style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="ts20"
              src="https://api.eesast.com/static/images/ts20.jpg"
            />
            <br />
            队式二十——THU-AI 枪林弹雨组
            <br />
          </Paragraph> */}
        </Typography>
      </TabPane>
      <TabPane tab="project 2" key="p2">
        <Typography className={styles.content}>
          {/* <Title level={2}>电子设计大赛</Title>
          <Title level={3}>赛事时间</Title>
          <Paragraph>
            电子设计大赛在每年的【秋季学期】举行，从报名领取元件到决赛整个过程大概会持续半个学期，包括赛前培训，预赛初赛决赛等阶段，让同学们从机械结构到电路设计，从控制逻辑到上层策略，一步步完成作品。
          </Paragraph>
          <Divider />
          <Title level={3}>赛事简介</Title>
          <Paragraph>
            “电子设计大赛”是由清华大学电子系和自动化系合办的面向全校的比赛，选手可以组成不多于四人的队伍报名参加比赛。比赛一般是要求选手设计一辆智能车，根据赛题内容设计机械结构，编写单片机代码，实现自动控制。比赛形式是让两支队伍的智能车进行对抗，根据规则，胜者晋级，比赛过程中智能车除了根据传感器检测场地情况之外，还可以通过官方提供的通信模块从上位机处获取信息或者给上位机发送指令；决赛在罗姆楼进行，每年都会吸引大批观众，一边欣赏紧张激烈的比赛，一边听着激情洋溢的解说，见证冠军的诞生。
          </Paragraph>
          <Title level={4}>精彩回顾</Title>
          <Paragraph disabled style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="edc18"
              src="https://api.eesast.com/static/images/edc18.jpg"
            />
            <br />
            第十八届电子设计大赛——天赐良机
            <br />
          </Paragraph>
          <Paragraph disabled style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="edc19"
              src="https://api.eesast.com/static/images/edc19.jpg"
            />
            <br />
            第十九届电子设计大赛——绿茵荣耀
            <br />
          </Paragraph>
          <Paragraph disabled style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="edc20"
              src="https://api.eesast.com/static/images/edc20.jpg"
            />
            <br />
            第二十届电子设计大赛——智圆行方
            <br />
          </Paragraph>
        </Typography>
      </TabPane>
      <TabPane tab="硬件设计大赛" key="hdc">
        <Typography className={styles.content}>
          <Title level={2}>硬件设计大赛</Title>
          <Title level={3}>赛事时间</Title>
          <Paragraph>
            硬件设计大赛在每年【暑假小学期】举办，大概持续两周时间。
          </Paragraph>
          <Divider />
          <Title level={3}>赛事简介</Title>
          <Paragraph>
            硬件设计大赛是清华大学电子系主办的一个比赛，主要面向电子系零基础的同学，赛前有若干次培训讲座，现场发放相应的模块，带领大家从零开始接触、学习单片机的基本操作，旨在激发同学们对硬件的热情。
          </Paragraph>
          <Title level={4}>精彩回顾</Title>
          <Paragraph style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="hwdc2017-1"
              src="https://api.eesast.com/static/images/hwdc2017-1.jpg"
            />
            <img
              className={styles.img}
              alt="hwdc2017-2"
              src="https://api.eesast.com/static/images/hwdc2017-2.jpg"
            />
            <br />
            <Text disabled>2017 年硬件设计大赛作品</Text>
            <br />
          </Paragraph>
          <Paragraph style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="hwdc2018-1"
              src="https://api.eesast.com/static/images/hwdc2018-1.gif"
            />
            <img
              className={styles.img}
              alt="hwdc2018-2"
              src="https://api.eesast.com/static/images/hwdc2018-2.jpg"
            />
            <br />
            <Text disabled>2018 年硬件设计大赛作品</Text>
            <br />
          </Paragraph>
          <Paragraph style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="hwdc2019-1"
              src="https://api.eesast.com/static/images/hwdc2019-1.gif"
            />
            <img
              className={styles.img}
              alt="hwdc2019-2"
              src="https://api.eesast.com/static/images/hwdc2019-2.gif"
            />
            <br />
            <Text disabled>2019 年硬件设计大赛作品</Text>
            <br />
          </Paragraph> */}
        </Typography>
      </TabPane>
      <TabPane tab="project 3" key="p3">
        <Typography className={styles.content}>
          {/* <Title level={2}>软件设计大赛</Title>
          <Title level={3}>赛事时间</Title>
          <Paragraph>
            软件设计大赛在每年【寒假前后】举办。软件设计大赛要求参赛者使用当今主流的技术栈，面向某个特定需求，完成一套具备一定可用性和美观性的软件程序。对于“软件”的定义十分宽泛，游戏、网站、APP
            都可以作为作品提交。
          </Paragraph>
          <Divider />
          <Title level={3}>参赛要求</Title>
          <Paragraph>
            <ul>
              <li>
                参赛者以个人为单位参加比赛。每名同学也可提交不超过 2 个作品。
              </li>
              <li>参赛选手应当是清华大学在校学生。</li>
              <li>
                参赛者应当在指定时间内以指定的方式完成队伍报名和程序提交。
              </li>
            </ul>
          </Paragraph>
          <Divider />
          <Title level={3}>作品要求</Title>
          <ul>
            <li>美观性：提交作品需要对 UI 有基本的设计，具有一定的美观性。</li>
            <li>功能性：提交作品需要针对某一类需求，提供一种解决方案。</li>
            <li>原创性：提交作品应当为选手的原创作品。</li>
            <li>
              稳定性：提交作品需要具有一定的稳定性，尽可能的减少异常和出错的概率。
            </li>
          </ul>
          <Title level={4}>精彩回顾</Title>
          <Paragraph disabled style={{ textAlign: "center" }}>
            2018 软件设计大赛优秀作品
            <br />
            <img
              className={styles.img}
              alt="swdc2018-1"
              src="https://api.eesast.com/static/images/swdc2018-1.png"
            />
            <br />
            多功能计算器 CalcPro
            <br />
            <img
              className={styles.img}
              alt="swdc2018-2"
              src="https://api.eesast.com/static/images/swdc2018-2.jpg"
            />
            <br />
            简易操作系统 BASIC-OS1
            <br />
            <img
              className={styles.img}
              alt="swdc2018-3"
              src="https://api.eesast.com/static/images/swdc2018-3.jpg"
            />
            <br />
            校园网管理软件 .NetCampus
            <br />
          </Paragraph>
          <Paragraph disabled style={{ textAlign: "center" }}>
            2019 软件设计大赛优秀作品
            <br />
            <img
              className={styles.img}
              alt="swdc2019-1"
              src="https://api.eesast.com/static/images/swdc2019-1.png"
            />
            <br />
            音乐节奏游戏 TuneStory
            <br />
            <img
              className={styles.img}
              alt="swdc2019-2"
              src="https://api.eesast.com/static/images/swdc2019-2.png"
            />
            <br />
            三角形滤镜 LowPoly
            <br />
            <img
              className={styles.img}
              alt="swdc2019-3"
              src="https://api.eesast.com/static/images/swdc2019-3.png"
            />
            <br />
            在线多功能绘图
            <br />
          </Paragraph>*/}
        </Typography>
      </TabPane>
      <TabPane tab="project 4" key="p4">
        <Typography className={styles.content}>
          {/* <Title level={2}>挑战杯</Title>
          <Title level={3}>赛事时间</Title>
          <Paragraph>
            11 月中旬：开始报名；次年 3 月：进行初审；4 月：进行二审和终审。
          </Paragraph>
          <Divider />
          <Title level={3}>赛事简介</Title>
          <Paragraph>
            清华大学“挑战杯”学生课外学术科技作品竞赛是由教务处、科研院、研究生院、校团委和校学生科协共同主办的全校性学生课外学术科技作品竞赛。挑战杯是清华大学历史最长、规模最大、水平最高的综合性学生课外科技作品竞赛，在电子系承办的诸多赛事中对参赛者的综合能力要求最高，能够显著提升参赛者的创新能力和创新意识。
          </Paragraph>
          <Paragraph>
            参赛流程如下：
            <ul>
              <li>
                选题：一个好的选题能大大提升获奖几率，鼓励大家选择创新性强、实用性佳且技术难度不过于高的项目。
              </li>
              <li>
                展开研究或制作：确定题目后，应在指导老师的指导下展开调查与分析，勇于尝试并精心完成题目研究或发明制作。
              </li>
              <li>
                送审：3
                月会要求选手提交作品初稿并进行系内评审，一般采取现场展示答辩的形式。通过初审的作品会送到校科协参加第二轮评审。二审和终审均安排在四月。
              </li>
              <li>
                参加科展：学校将于 4
                月末校庆期间将参赛作品统一布展，在全校内进行展评。其间，在电子系的展区内，选手可以通过展板、作品实物和讲解来展示自己的作品。
              </li>
              <li>
                参加全国赛/申请专利等：如果你的项目足够牛，就可以代表清华大学参加全国的挑战杯；或者也可以申请专利，投入商业应用。
              </li>
            </ul>
          </Paragraph>
          <Divider />
          <Title level={3}>往年情况</Title>
          <Title level={4}>32 届挑战杯获奖情况</Title>
          <Paragraph>
            一等奖
            <ul>
              <li>超高精度的自然手势获取与识别技术研究</li>
            </ul>
          </Paragraph>
          <Paragraph>
            三等奖
            <ul>
              <li>知了——基于 SNS 的校园活动信息推送系统</li>
              <li>基于数字图像处理的机械臂自动校准技术</li>
              <li>基于红外光通信的智能车远距离对抗平台</li>
              <li>搭建在树莓派上基于人脸识别的自动录播系统</li>
              <li>基于深度学习的人脸表情处理系统</li>
            </ul>
          </Paragraph>
          <Title level={4}>34 届挑战杯获奖情况</Title>
          <Paragraph>
            一等奖
            <ul>
              <li>千里眼——基于虚拟现实技术的全景视频拼接编码传输系统</li>
            </ul>
          </Paragraph>
          <Paragraph>
            三等奖
            <ul>
              <li>基于 real sense 智能交互平台的识别系统研究</li>
              <li>多目标跟踪算法通用平台的设计与实现</li>
            </ul>
          </Paragraph>
          <Title level={4}>35 届挑战杯获奖情况</Title>
          <Paragraph>
            三等奖
            <ul>
              <li>高能效手写文字识别系统设计</li>
            </ul>
          </Paragraph>
          <Title level={4}>36 届挑战杯获奖情况</Title>
          <Paragraph>
            二等奖
            <ul>
              <li>基于多注意力语义表示机制的医考问答系统</li>
              <li>社交媒体噪声文本的维度情感分析系统</li>
              <li>基于 sEMG 信号的便携式帕金森病诊断仪</li>
              <li>智能自主协同编队系统</li>
            </ul>
          </Paragraph>
          <Paragraph>
            三等奖
            <ul>
              <li>基于高精度室内定位技术的无人车导航编队系统</li>
              <li>基于面部表情与动作的唇语识别与语音合成系统</li>
              <li>基于上转换原理的高性能植入式光电子器件</li>
            </ul>
          </Paragraph>
          <Divider />
          <Title level={3}>学长经验</Title>
          <ul>
            <li>
              知识要求：一般来讲挑战杯所需知识与本科所学课程不会有太大的关系，无论是大三参加还是大二参加都会学习一些课外的知识。
            </li>
            <li>
              时间安排：一般来讲挑战杯主力在大三，但是对于电子系同学而言大三上是课业最重的一个学期，所以推荐同学们大二参加。在时间安排合理的情况下，是可以在学业顺利进行的同时好好做挑战杯的。
            </li>
            <li>
              选题建议：一般来讲实用性强、展示性强的项目更有可能会得到好成绩，建议同学们多多观察周边事物，可能就会有好点子冒出来。此外，对于重心放在科研、发论文上面的同学，可以考虑顺手也报一个挑战杯，往年也有一些选手靠纯科研成果拿到较好成绩。
            </li>
            <li>
              团队合作：很少有一个人做项目做到最后的；一般来说组一个小队，联系实验室指导老师会减轻一些做项目的压力。
            </li>
          </ul>*/}
        </Typography>
      </TabPane>
      <TabPane tab="project 5" key="p5">
        <Typography className={styles.content}>
          {/* <Title level={2}>新生信息知识竞赛</Title>
          <Title level={3}>赛事时间</Title>
          <Paragraph>
            ADI
            杯新生信息知识竞赛在【秋季学期】举行，在新生入学后会有相关的宣传工作，通常期中考试前结束所有工作。
          </Paragraph>
          <Divider />
          <Title level={3}>赛事简介</Title>
          <Paragraph>
            清华大学 ADI 杯新生信息知识竞赛至今已成功举办 15
            届。该活动是由清华大学信息学院四系科协（电子系，计算机系，自动化系，软件学院）合办的一年一届的全校活动。活动旨在向新生普及信息知识，发现人才。这项活动面向全校
            3000 多名新生，尤其是 1000
            多名信息学院的新同学，已在校内产生了良好而广泛的影响。
          </Paragraph>
          <Paragraph>
            全校初赛采取统一笔试环节，选取五到七支队伍进入决赛。电子系系内初赛及全校决赛试题以
            PowerPoint
            形式，有必答题、抢答题、视频题、女生题、人气题、你说我猜题、渐进抢答题和风险题等环节，均为现场作答。
          </Paragraph>
          <Divider />
          <Title level={3}>往年情况</Title>
          <Paragraph>
            往年奖品包括小米手环、极路由、蓝牙音箱、扫地机器人、打印机、移动硬盘等等，同时观赛亦有机会获得观众奖。
          </Paragraph>
          <Paragraph disabled style={{ textAlign: "center" }}>
            <img
              className={styles.img}
              alt="fic15"
              src="https://api.eesast.com/static/images/fic15.jpg"
            />
            <br />
            第十五届决赛
            <br />
            <img
              className={styles.img}
              alt="fic16"
              src="https://api.eesast.com/static/images/fic16.jpg"
            />
            <br />
            第十六届决赛
            <br />
            <img
              className={styles.img}
              alt="fic17"
              src="https://api.eesast.com/static/images/fic17.jpg"
            />
            <br />
            第十七届决赛
            <br />
          </Paragraph>*/}
        </Typography>
      </TabPane>
    </Tabs>
  );
};

export default ContestPage;
